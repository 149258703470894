.carousel {
  img {
    height: 70vh;
  }
  @media only screen and (max-width: 576px) {
    display: none;
  }
  img {
    max-height: 75vh;
    @media only screen and (min-width: 720px) {
      object-fit: cover;
    }
  }

  .sphere {
    text-shadow: -3px -1px 3px rgba(0, 0, 0, 1);
  }
}
.parameters {
    display: flex;
    justify-content: space-between;
    
    select {
        width: 100%;
    }
    div:nth-child(2) {
        margin-top: 32px;
        margin-left: 20px;
    }
}

.parameters-2 {
    div {
        margin: 6px 0;
        width: 100%;
    }
}
@import '../../../styles/global.scss';

.title {
  iframe {
    height: 60vh;
    width: 100%;
  }
  
  h2 {
    margin-top: 20px;
    margin-bottom: 100px;
  }  
}

.dots-container {
  display: flex;
  font-size: 22px;
  flex-direction: column;


  svg {
    font-size: 24px;
    color: $color-main;
    margin-right: 20px;
  }
}

.info-container {
  align-items: center;
  display: flex;
  font-size: 18px;
  text-align: justify;

  span {
    svg {
      font-size: 50px;
      color: $color-main;
      margin: 20px;
    }
  }

  @media only screen and (max-width: 991px) {
    flex-direction: column;
  }
}

.promotion {
  align-items: center;
  background-color: $main-dark;
  color: white;
  display: flex;
  font-size: 25px;
  height: 150px;
  justify-content: center;
  margin: 100px 0;
  text-align: center;
  width: 100%;
}
.how-its-made-title {
    color: $main-dark;
    margin-bottom: 50px;
    text-align: center;

}
.how-its-made {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .number {
    align-items: center;
    background-image: url('../../../../public/photo.png');
    border-radius: 50%;
    color: white;
    display: flex;
    font-size: 24px;
    font-weight: 700;
    justify-content: center;
    height: 100px;
    margin: 0 0 20px 0;
    width: 100px;

  }

  p {
    min-height: 100px;
  }

  &:nth-child(2) {
    .number {
      background-image: url('../../../../public/computer.png');
    }
  }

  &:nth-child(3) {
    .number {
      background-image: url('../../../../public/web.png');
    }
  }

  @media only screen and (max-width: 991px) {
    text-align: center;
  }
}
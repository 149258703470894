@import '../../../styles/global.scss';

.container {
  margin-top: 100px;
  margin-bottom: 150px;
  text-align: center;

  @media only screen and (max-width: 576px) {
    margin: 0 0 50px 0;
  }

  @media only screen and (max-width: 576px) {
    margin: 0 0 50px 0;
  }


  h2 {
    padding-bottom: 10px;
    img {
      height: 120px;
    }
  }

  & > p {
    padding: 0 10px;
  }

  .description-container {
    display: flex;
    align-items: center;
  
    .photo {
      align-items: center;
      display: flex;
      justify-content: center;
      
      width: 40%;

      img {
        object-fit: cover;
      }

      @media only screen and (max-width: 576px) {
        display: none;
      }
    }
    .description {

      @media only screen and (max-width: 576px) {
        width: 100%;
      }

      .description-desktop {
        -webkit-box-shadow: 0px 0px 5px 1px rgba(230, 230, 255, 1);
        -moz-box-shadow: 0px 0px 5px 1px rgba(230, 230, 255, 1);
        box-shadow: 0px 0px 5px 1px rgba(230, 230, 255, 1);
        border-radius: 10px;
        height: 100%;
        padding: 32px;
        text-align: justify;

        .description-icon {
          align-items: center;
          border-radius: 10px;
          background-color: #EEF5FF;
          display: flex;
          justify-content: center;
          height: 60px;
          margin-bottom: 20px;
          width: 60px;

          svg {
            color: $main-dark;
            font-size: 24px;
          }
        }

        @media only screen and (max-width: 576px) {
          display: none;
        }

        h3 {
          color: $main-darkest;
          font-size: 20px;
          margin-bottom: 15px;         
        }
      }

    }
  }
}

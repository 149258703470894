.modal-container {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;

  .image-container {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;

    .close {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 30px;
      color: white;
      z-index: 1;
      opacity: 0.5;

      &:hover {
        opacity: 1;
      }
    }

    &:hover {
      .arrow-left, .arrow-right {
        opacity: 0.2;
      }
    }

    .arrow-left, .arrow-right {
      border: none;
      cursor: pointer;
      opacity: 0.0;
      align-items: center;
      display: flex;
      justify-content: center;
      position: absolute;
      font-size: 35px;
      background-color: rgb(0, 0, 0, 0.5);
      width: 10vw;
      max-width: 75px;

      height: 100%;

      &:hover {
        opacity: 0.5;
      }
      svg {
        color: white;
      }
    }
    .arrow-left {
      left: 0;
    }
    .arrow-right {
      right: 0;
    }
    img {
      max-height: 90%;
    }
  }



}
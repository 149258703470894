@import '../../../styles/global.scss';

.about-container {
  text-align: center;
  margin-bottom: 100px;

  h2 {
    color: $main-dark;
    font-size: 16px;
    font-weight: 700;
  }

  h3 {
    font-size: 50px;
    margin-bottom: 50px;
  }

  p {
    text-align: justify;
    font-size: 20px;
    margin-left: 50px;
    margin-right: 50px;
  }

  img {
    border-radius: 10px;
  }

  @media only screen and (max-width: 991px) {
    p {
      font-size: 16px;
    }
  }

  @media only screen and (max-width: 767px) {
    img {
      margin-bottom: 20px;
    }

    h3 {
      font-size: 30px;
    }
  }
}

.right-side {
  background-color: $color-main;
  color: white;
  padding: 100px 0;

  h2 {
    color: white;
  }

  @media only screen and (max-width: 991px) {
    .social-image {
      order: -1;
    }
  }
}

.info-container {
  text-align: center;
  max-width: 996px;

  h2 {
    font-size: 50px;
  }

  h3 {
    margin-bottom: 20px;
  }

  .info, .info-bottom {
    align-items: center;
    border-right: 1px solid #E1EDFF;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
  
    p {
      min-height: 150px;
    }
  
    &:nth-child(3) {
      border-right: none;
    }
  
    &:nth-child(6) {
      border-right: none;
    }

    @media only screen and (max-width: 991px) {
      &:nth-child(2) {
        border-right: none;
      }

      &:nth-child(3) {
        border-right: 1px solid #E1EDFF;
      }
      
      &:nth-child(4) {
        border-right: none;
        border-bottom: 1px solid #E1EDFF;
      }      
      
      &:nth-child(6) {
        border-right: none;
      }
    }

    @media only screen and (max-width: 575px) {
      border-bottom: 1px solid #E1EDFF;

      &:nth-child(6) {
        border-bottom: none;
      }
    }
  
    .number {
      align-items: center;
      border: 1px solid $color-main;
      border-radius: 50%;
      display: flex;
      height: 65px;
      justify-content: center;
      margin: 20px 0;
      width: 65px;
    }
  }
  
  
  .info {
    border-bottom: 1px solid #E1EDFF;
  }
}

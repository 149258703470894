@import '../../../styles/global.scss';

.image-container {
  position: relative;
  height: 40vh;

  img {
    cursor: pointer;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  .arrow {
    display: none;
  }

  &:hover {
    .arrow {
      border: none;
      cursor: pointer;
      position: absolute;
      top: 0;
      height: 100%;
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(173, 196, 206, 0.5);
      margin: 0 12px;
  
      &:nth-of-type(1) {
        left: 0;
      }
      &:nth-of-type(2) {
        right: 0;
      }
    }
    svg {
      cursor: pointer;
      font-size: 45px;
      color: rgb(173, 196, 206, 0.8);
      &:first-of-type {
        right: 0px;
      }
    }  
  }
}
.miniatures-container {
  position: relative;
  
  ::-webkit-scrollbar{
    height: 6px;
    background: rgb(173, 196, 206, 0.1);
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: rgb(173, 196, 206, 0.2);
  }
 
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888; 
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

  ::-webkit-scrollbar-thumb:horizontal{
      background: rgb(173, 196, 206, 0.9);
      border-radius: 10px;
  }

  .arrows {
    color: $main-darkest;

    svg {
      cursor: pointer;
      position: absolute;
      font-size: 25px;
      right: 0;
      bottom: 50%;
  
      &:first-of-type {
        left: 0;
      }
    }

    @media only screen and (max-width: 576px) {
      display: none;
    }
  }

  .images-container {
    display: flex;
    height: 20vh;
    overflow: auto;
    overscroll-behavior-inline: contain;
    scroll-behavior: smooth;

    img {
      cursor: pointer;
      height: 90%;
      margin-bottom: 10px;
      width: 100%;
      object-fit: cover;

      &.active {
        border: 5px solid $color-main;
        padding: 5px;
      }
    }
  
    @media only screen and (max-width: 576px) {
      display: none;
    }
  
    .miniature {
      min-width: calc(100% / 5);
      max-width: calc(100% / 5);
      padding: 0 20px;
    }
    @media only screen and (max-width: 995px) {
      .miniature {
        min-width: calc(100% / 3);
        max-width: calc(100% / 3);
          padding: 0 20px;
      }
    }
  }
}
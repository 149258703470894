/* Global Varibles */
$color-main: #86B6F6;
$main-dark: #1170B7;
//$main-darkest: #27374D;
$main-darkest: #2C2E83;
$main-light: #2DAAE1;
$main-lightes: #EEF5FF;


/* 
#437FC7
#6DAFFE
#EDF6FF
#FFFFFF
#B9732F

$color-main: #86B6F6;
$main-dark: #176B87;
$main-darkest: #27374D;
$main-light: #B4D4FF;
$main-lightes: #EEF5FF;
*/
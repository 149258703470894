@import '../../../styles/global.scss';

.header {
  border: none !important;
  
  svg {
    color: $main-darkest;
    margin: 10px;
  }
  h2 {
    border: none !important;
  }
}
@import '../../../styles/global.scss';
.drones {
    color: rgb(19, 21, 74);

    .container {
        height: 75vh;
        
        h2 {
            margin-bottom: 20px;
            font-size: 42px;
            text-align: left;
            margin-left: 20px;
        }
        
        p {
            font-size: 20px;
            margin-left: 20px;
            text-align: justify;
        }

        .yt-description {
            h2 {
                margin-right: 20px;
            }

            font-size: 20px;

            div {
                margin: 20px 0;
            }
            
            span {
                margin: 0 20px;

                svg {
                    font-size: 22px;
                    color: $color-main;
                }
            }

            @media only screen and (max-width: 450px) {
                font-size: 16px;
            } 
        }
        .youtube {
            display: flex;
            justify-content: center;
        }

        @media only screen and (max-width: 770px) {
            h2 {
                font-size: 36px;
            }
        }

        @media only screen and (max-width: 450px) {
            h2 {
                font-size: 28px;
            }

            p {
                font-size: 16px;
            }
        } 
        
        img, iframe {
            border-radius: 4%;
            -webkit-box-shadow: 5px 5px 22px -5px rgba(44, 46, 131, 1);
            -moz-box-shadow: 5px 5px 22px -5px rgba(44, 46, 131, 1);
            box-shadow: 5px 5px 22px -5px rgba(44, 46, 131, 1);
            width: 600px;
            height: 400px;

            @media only screen and (max-width: 770px) {
                height: 300px;
            }

            @media only screen and (max-width: 450px) {
                height: 220px;
            } 
        }

        .image {
            display: flex;
            justify-content: center;
        }

        @media only screen and (max-width: 1199px) {
            height: 100%;
            margin: 0 0 100px 0;

            .youtube {
                order: -1;
            }

            h2 {
                text-align: center;
                margin-left: 0;
            }

            p {
                margin-left: 0;
            }

            img, iframe {
                margin: 0 0 30px 0;
            }

        } 
    }
}

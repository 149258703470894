@import '../../../styles/global.scss';

.contact-container {
  align-items: center;
  background-color: $main-lightes;
  color: $main-darkest;
  display: flex;
  height: 60px;
  font-size: 13px;
  justify-content: center;
  width: 100%;

  a {
    color: $main-darkest;
    text-decoration: none;
    
    &:hover {
      color: $main-dark;
    }
  }

  svg {
    color: $main-dark;
    font-size: 30px;
  }

  .contact-detail {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 40px;
    
    span {
      margin: 0 20px;
    }

    .address {
      display: flex;
      flex-direction: column;
    }

    @media only screen and (max-width: 640px) {
      display: none;

      &:first-of-type {
        display: flex;
      }
    }
    @media only screen and (max-width: 880px) {
      &:last-of-type {
        display: none;
      }
    }

  }
}
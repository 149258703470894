@import '../../../styles/global.scss';

button {
  min-width: 80px;
}

.image-container {
  position: relative;

  img {
    cursor: pointer;
  }
  .arrow {
    display: none;
  }

  &:hover {
    .arrow {
      cursor: pointer;
      position: absolute;
      top: 0;
      height: 100%;
      width: 10%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgb(173, 196, 206, 0.5);
      margin: 0 12px;
  
      &:nth-of-type(1) {
        left: 0;
      }
      &:nth-of-type(2) {
        right: 0;
      }
    }
    svg {
      cursor: pointer;
      font-size: 45px;
      color: rgb(173, 196, 206, 0.8);
      &:first-of-type {
        right: 0px;
      }
    }  
  }
}

.details-container {
  height: 100%;

  .icon {
    //color: #27374D;
    color: $main-dark;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    font-size: 20px;
    margin-right: 10px;
  }

  div {
    display: flex;
    align-items: center;
  }
  .detail-grey {
    background-color: #e1edff;
  }
  .detail-lightgrey {
    background-color: #EEF5FF;
  }

  .detail {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
}
.miniatures-container {
  position: relative;
  
  .arrows {
    svg {
      cursor: pointer;
      position: absolute;
      font-size: 25px;
      right: 0;
      bottom: 50%;
  
      &:first-of-type {
        left: 0;
      }
    }
  }

  .images-container {
    display: flex;
    overflow: auto;
    overscroll-behavior-inline: contain;
    scroll-behavior: smooth;

    img {
      cursor: pointer;
      transition: all 0.1s ease-out;

      &.active {
        border: 5px solid rgb(173, 196, 206);
        padding: 5px;
      }
    }
  
    @media only screen and (max-width: 576px) {
      display: none;
    }
  
    .miniature {
      min-width: calc(100% / 5);
      padding: 0 20px;
    }
  }
}
@import '../../../styles/global.scss';


h2 {
  color: $main-dark;
  margin-bottom: 20px;
  text-align: center;
}
.socialbar-container {
  background-color: $main-dark;
  color: white;
  height: 280px;
  text-align: center;

  a {
    align-items: center;
    display: flex;
    justify-content: center;
    height: 280px;
    width: 25%;
    transition: all 0.3s ease;

    @media only screen and (max-width: 770px) {
      height: 140px;
      width: 50%;
    }
    svg {
      color: white;
      font-size: 100px;
    }

    &:hover {
      background-color: white;
      cursor: pointer;

      svg {
        color: $main-dark;
      }
    }
  }


}
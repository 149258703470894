@import '../../../styles/global.scss';

.choice {
  display: flex;
  justify-content: center;
  font-weight: 600;
  width: 100%;
  transition: all 0.3s ease;

  & > div {
    display: flex;
    justify-content: center;
    margin: 25px 0;
    padding-bottom: 10px;
    width: 230px;
    transition: all 0.3s ease;


    &:hover {
      cursor: pointer;
      transition: all 0.3s ease;

    }
    
    &.active {
      border-bottom: 1px solid $main-dark;
      color: $main-dark;
      font-weight: 600;
      transition: all 0.3s ease;

    }
  }
  span {
    font-size: 20px;
  }
}

.services-container {
  text-align: justify;

  h2 {
    font-size: 25px;
    text-align: start;
  }

  .number {
    align-items: center;
    display: flex;
    justify-content: center;
    position: relative;

    .bar {
      background-color: #EEF5FF;
      width: 2px;
      height: 100%;
      position: absolute;
      bottom: 0;
      z-index: -1;
    }

    .bar-first {
      background-color: #EEF5FF;
      width: 2px;
      height: 50%;
      position: absolute;
      bottom: 0;
      z-index: -1;
    }

    .bar-last {
      background-color: #EEF5FF;
      width: 2px;
      height: 50%;
      position: absolute;
      top: 0;
      z-index: -1;

      @media only screen and (max-width: 991px) {
        top: 50%;
      }
    }

    span {
      align-items: center;
      border: none;
      border-radius: 50%;
      background-color: $color-main;
      color: white;
      display: flex;
      font-size: 20px;
      font-weight: 700;
      height: 35px;
      justify-content: center;
      width: 35px;

      @media only screen and (max-width: 991px) {
        margin-bottom: 20px;
      }
    }
  }
  .icon {
    align-items: center;
    display: flex;
    justify-content: center;
  }
}

.inactive {
  display: none;
  opacity: 0;
}

.active0 {
  animation: myAnim 2s ease 0s 1 normal forwards;
  display: flex;

  @keyframes myAnim {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
}


.active1 {
  animation: myAnim 2s ease 0.5s 1 normal forwards;
  display: flex;

  @keyframes myAnim {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
}

.active2 {
  animation: myAnim 2s ease 1s 1 normal forwards;
  display: flex;

  @keyframes myAnim {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
}

.active3 {
  animation: myAnim 2s ease 1.5s 1 normal forwards;
  display: flex;

  @keyframes myAnim {
    0% {
      opacity: 0;
    }
  
    100% {
      opacity: 1;
    }
  }
}
@import '../../../styles/global.scss';

footer {
  background: #EEF5FF;
  color: $main-darkest;
  justify-content: center;
  font-size: 13px;

  a {
    text-decoration: none;
    color: $main-darkest;

    &:hover {
      color: $main-dark;
    }
  }
  
  img {
    height: 100px;
  }
  
  div {
    justify-content: center;
    @media only screen and (max-width: 991px) {
      text-align: center;
      justify-content: center;
    }
  }
  .social-icon {
    align-items: center;
    display: flex;
    font-size: 40px;
    justify-content: center;
    margin: 0 30px;

    a {
      color: $main-darkest;
    }

    &:hover {
      cursor: pointer;

      a {
        color: $main-dark;
      }
    }
  }
}

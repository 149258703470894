@import '../../../styles/global.scss';

.container {
    h2 {
        font-size: 16px;
        font-weight: 700;
    }

    h3 {
        font-size: 50px;
        margin-bottom: 50px;
        text-align: center;
    }
}

.header-container {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    p {
        font-size: 18px;
    }

    .image-container {
        align-items: center;
        display: flex;
        justify-content: center;
        margin-bottom: 30px;
    }
}

.details {
    align-items: center;
    display: flex;
    justify-content: center;
}

.contact-container {
    align-items: center;
    background-color: $color-main;
    display: flex;
    height: 500px;
    justify-content: center;
    margin: 50px 0 0 0;

    a {
        text-decoration: none;
        color: $main-darkest;

        &:hover {
            color: $main-dark;
        }
    }

    @media only screen and (max-width: 991px) {
        height: 1300px;
    }

    .detail-container {
        align-items: center;
        background-color: white;
        border-radius: 10px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 350px;
        margin: 20px;
        width: 300px;
        -webkit-box-shadow: 0px 0px 13px 0px rgba(255, 255, 255, 1);
        -moz-box-shadow: 0px 0px 13px 0px rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 13px 0px rgba(255, 255, 255, 1);

        span {
            margin-bottom: 5px;
        }
        
        .icon {
            align-items: center;
            border-radius: 50%;
            border: 1px solid $color-main;
            display: flex;
            font-size: 30px;
            height: 70px;
            justify-content: center;
            width: 70px;
            margin-bottom: 20px;
    
            svg {
                color: $color-main;
            }
    
        }
    
        .phone {
            align-items: center;
            color: $main-dark;
            display: flex;
            font-size: 20px;
            font-weight: 700;
            flex-direction: column;
            height: 230px;
            justify-content: center;
            min-height: 120px;

            span:nth-child(even) {
                color: black;
                font-size: 24px;
            }
        }

        .email {
            align-items: center;
            color: $main-dark;
            display: flex;
            font-size: 18px;
            font-weight: 700;
            flex-direction: column;
            height: 230px;
            justify-content: center;
            min-height: 120px;
    
            span:nth-child(even) {
                color: black;
                font-size: 15px;
            }
        }

        .address {
            align-items: center;
            color: $main-darkest;
            display: flex;
            font-size: 15px;
            font-weight: 700;
            flex-direction: column;
            height: 230px;
            justify-content: center;
            text-align: center;
            min-height: 120px;

            span:first-child {
                color: $main-dark;
            }
        }

    }
}

/*
.container-contact {
    margin-bottom: 10px;
    .header {
        align-items: center;
        border-bottom: 1px solid $main-darkest;
        display: flex;
        height: 50px;
        margin: 0 0 20px 0;
      
        h2 {
          margin-left: 10px;
        }
      }
    .contact {
        span {
            align-items: center;
            display: flex;
            justify-content: center;
            margin: 5px 0;
            width: 100%;

            svg {
                font-size: 25px;
            }
        }

        svg {
            color: $main-dark;
            font-size: 40px;
            margin: 10px 0;
        }

        a {
            color: black;
            text-decoration: none;
        }

        .user-data {
            display: flex;
            align-items: center;
            justify-content: center;
            
            span {
                display: flex;
                justify-content: center;
                width: 25px;
                margin: 0 10px 0 0;
            }
        }
    }

    .social-icon {
        align-items: center;
        color: $main-darkest;
        display: flex;
        font-size: 50px;
        height: 60px;
        justify-content: center;
        margin: 0 50px;
        width: 60px;

        &:hover {
            color: $main-dark;
            cursor: pointer;
        }
    }
}
*/